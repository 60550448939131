import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./app";
import {
	QueryClient,
	QueryClientProvider,
	useQuery,
} from '@tanstack/react-query';

const queryClient = new QueryClient();
const paymentElement = document.getElementById("web3Modal_body");

import { useWeb3ModalTheme } from "@web3modal/react";

const root = createRoot(paymentElement);

// const paymentElement2 = document.getElementById("web3Modal_body");
// const root2 = createRoot(paymentElement2);


// root2.render(

// 	<QueryClientProvider client={queryClient}>
// 		<App />

// 	</QueryClientProvider>

// );
root.render(

	<QueryClientProvider client={queryClient}>
		<App />

	</QueryClientProvider>

);
